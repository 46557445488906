/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNest = /* GraphQL */ `
  mutation CreateNest($name: String!) {
    createNest(name: $name) {
      nestId
      name
      createdAt
      owner
      stories {
        nestId
        storyId
        title
        description
        createdAt
        dateToBeCompleted
        owner
        status
        priority
        effort
        sprint
      }
      users {
        username
        email
      }
      sprints
    }
  }
`;
export const addNestUser = /* GraphQL */ `
  mutation AddNestUser($nestId: ID!, $email: String!) {
    addNestUser(nestId: $nestId, email: $email) {
      nestId
      name
      createdAt
      owner
      stories {
        nestId
        storyId
        title
        description
        createdAt
        dateToBeCompleted
        owner
        status
        priority
        effort
        sprint
      }
      users {
        username
        email
      }
      sprints
    }
  }
`;
export const removeNestUser = /* GraphQL */ `
  mutation RemoveNestUser($nestId: ID!, $email: String!) {
    removeNestUser(nestId: $nestId, email: $email) {
      nestId
      name
      createdAt
      owner
      stories {
        nestId
        storyId
        title
        description
        createdAt
        dateToBeCompleted
        owner
        status
        priority
        effort
        sprint
      }
      users {
        username
        email
      }
      sprints
    }
  }
`;
export const createStory = /* GraphQL */ `
  mutation CreateStory(
    $nestId: ID!
    $title: String!
    $description: String
    $owner: String
    $status: Status
    $sprint: Int
  ) {
    createStory(
      nestId: $nestId
      title: $title
      description: $description
      owner: $owner
      status: $status
      sprint: $sprint
    ) {
      nestId
      name
      createdAt
      owner
      stories {
        nestId
        storyId
        title
        description
        createdAt
        dateToBeCompleted
        owner
        status
        priority
        effort
        sprint
      }
      users {
        username
        email
      }
      sprints
    }
  }
`;
export const addStoryAttachment = /* GraphQL */ `
  mutation AddStoryAttachment(
    $nestId: ID!
    $storyId: ID!
    $name: String!
    $key: String!
  ) {
    addStoryAttachment(
      nestId: $nestId
      storyId: $storyId
      name: $name
      key: $key
    ) {
      nestId
      storyId
      title
      description
      createdAt
      dateToBeCompleted
      owner
      status
      attachments {
        name
        key
        createdAt
      }
      comments {
        username
        content
        createdAt
      }
      priority
      effort
      sprint
    }
  }
`;
export const deleteStoryAttachment = /* GraphQL */ `
  mutation DeleteStoryAttachment($nestId: ID!, $storyId: ID!, $key: String!) {
    deleteStoryAttachment(nestId: $nestId, storyId: $storyId, key: $key) {
      nestId
      storyId
      title
      description
      createdAt
      dateToBeCompleted
      owner
      status
      attachments {
        name
        key
        createdAt
      }
      comments {
        username
        content
        createdAt
      }
      priority
      effort
      sprint
    }
  }
`;
export const addComment = /* GraphQL */ `
  mutation AddComment($nestId: ID!, $storyId: ID!, $comment: String!) {
    addComment(nestId: $nestId, storyId: $storyId, comment: $comment) {
      nestId
      storyId
      title
      description
      createdAt
      dateToBeCompleted
      owner
      status
      attachments {
        name
        key
        createdAt
      }
      comments {
        username
        content
        createdAt
      }
      priority
      effort
      sprint
    }
  }
`;
export const updateStory = /* GraphQL */ `
  mutation UpdateStory(
    $nestId: ID!
    $storyId: ID!
    $status: Status!
    $description: String
    $comment: String
    $priority: Priority
    $effort: Int
    $owner: String
    $dateToBeCompleted: String
    $sprint: Int
  ) {
    updateStory(
      nestId: $nestId
      storyId: $storyId
      status: $status
      description: $description
      comment: $comment
      priority: $priority
      effort: $effort
      owner: $owner
      dateToBeCompleted: $dateToBeCompleted
      sprint: $sprint
    ) {
      nestId
      name
      createdAt
      owner
      stories {
        nestId
        storyId
        title
        description
        createdAt
        dateToBeCompleted
        owner
        status
        priority
        effort
        sprint
      }
      users {
        username
        email
      }
      sprints
    }
  }
`;
export const deleteStory = /* GraphQL */ `
  mutation DeleteStory($nestId: ID!, $storyId: ID!) {
    deleteStory(nestId: $nestId, storyId: $storyId) {
      nestId
      name
      createdAt
      owner
      stories {
        nestId
        storyId
        title
        description
        createdAt
        dateToBeCompleted
        owner
        status
        priority
        effort
        sprint
      }
      users {
        username
        email
      }
      sprints
    }
  }
`;
export const addSprint = /* GraphQL */ `
  mutation AddSprint($nestId: ID!) {
    addSprint(nestId: $nestId) {
      nestId
      name
      createdAt
      owner
      stories {
        nestId
        storyId
        title
        description
        createdAt
        dateToBeCompleted
        owner
        status
        priority
        effort
        sprint
      }
      users {
        username
        email
      }
      sprints
    }
  }
`;
