/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const nestStories = /* GraphQL */ `
  subscription NestStories($nestId: ID!) {
    nestStories(nestId: $nestId) {
      nestId
      name
      createdAt
      owner
      stories {
        nestId
        storyId
        title
        description
        createdAt
        dateToBeCompleted
        owner
        status
        priority
        effort
        sprint
      }
      users {
        username
        email
      }
      sprints
    }
  }
`;
