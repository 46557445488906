import React, { Component } from "react";

class Epics extends Component {
  state = {};
  render() {
    return <h1>Epics page loaded!</h1>;
  }
}

export default Epics;
