/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const nest = /* GraphQL */ `
  query Nest($nestId: ID!, $sprint: Int) {
    nest(nestId: $nestId, sprint: $sprint) {
      nestId
      name
      createdAt
      owner
      stories {
        nestId
        storyId
        title
        description
        createdAt
        dateToBeCompleted
        owner
        status
        priority
        effort
        sprint
      }
      users {
        username
        email
      }
      sprints
    }
  }
`;
export const nests = /* GraphQL */ `
  query Nests {
    nests {
      nestId
      name
      createdAt
      owner
      stories {
        nestId
        storyId
        title
        description
        createdAt
        dateToBeCompleted
        owner
        status
        priority
        effort
        sprint
      }
      users {
        username
        email
      }
      sprints
    }
  }
`;
export const story = /* GraphQL */ `
  query Story($nestId: ID!, $storyId: ID!) {
    story(nestId: $nestId, storyId: $storyId) {
      nestId
      storyId
      title
      description
      createdAt
      dateToBeCompleted
      owner
      status
      attachments {
        name
        key
        createdAt
      }
      comments {
        username
        content
        createdAt
      }
      priority
      effort
      sprint
    }
  }
`;
export const stories = /* GraphQL */ `
  query Stories($nestId: ID!, $sprint: Int) {
    stories(nestId: $nestId, sprint: $sprint) {
      nestId
      storyId
      title
      description
      createdAt
      dateToBeCompleted
      owner
      status
      attachments {
        name
        key
        createdAt
      }
      comments {
        username
        content
        createdAt
      }
      priority
      effort
      sprint
    }
  }
`;
export const users = /* GraphQL */ `
  query Users($prefix: String, $username: String) {
    users(prefix: $prefix, username: $username) {
      username
      email
    }
  }
`;
