/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9389378c-dfc1-4fde-9438-fa5af8732a17",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_7g7GJb6Za",
    "aws_user_pools_web_client_id": "35i0e4s09qakerb4m52km5v86e",
    "oauth": {},
    "aws_user_files_s3_bucket": "337451035854-scrumnest-files-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://mhgqg2raerbx7bkgtkdx26y3qm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
